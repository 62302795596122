export const GeneralHelper = {
    UpdateMetaTags: function (title, description, image){
        document.title =`Habitaki | ${title}`;
        let tags = document.getElementsByTagName("META");
        console.log("Tags", tags);
        document.getElementsByTagName("META")[3].content=description;
        if(image){
            document.querySelectorAll('meta[property=og\\:image]')[0].setAttribute('content', image)
        }
    }
}