import GetBaseUrl from "@/app/core/base/BaseAddress";
import { ChatController } from "@/app/core/base/controllers/ChatController";
import { FileController } from "@/app/core/base/controllers/FileController";
import { PropertiesController } from "@/app/core/base/controllers/PropertiesController";
import RealtyInLine from "@/app/core/layouts/main-components/realty/RealtyInLine";
import {
  DateToPast,
  DateTranslate,
  DateTranslateSimple,
  GetHours,
  IsTodayDate,
} from "@/app/core/util/DateTranslater";
import { StringHelper } from "@/app/core/util/helpers/StringHelper";
import CheckMobile from "@/app/core/util/Screen";
import Storage from "@/app/core/util/Storage";
import { Timestamp } from "firebase/firestore";
import React from "react";
import { useState, useEffect, useRef } from "react";

export default function Post(props) {
  const [isMobile, setIsMobile] = useState(CheckMobile);
  const [messages, setMessages] = useState([]);
  const [chats, setChats] = useState([]);
  const [chat, setChat] = useState({});
  const [property, setProperty] = useState({});
  const [chatId, setChatId] = useState(null);
  const [message, setMessage] = useState("");
  const [media, setMedia] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  let height = window.screen.height;
  const divisor = isMobile === true ? 1.5 : height === 1080 ? 1.5 : 1.8;

  const userData = Storage.getUserData();
  const messagesEndScrollRef = useRef(null);
  const fileInputRef = useRef(null);

  let chatsUnsubscribe;
  let messagesUnsubscribe;

  console.log("Altura", height, divisor, height/divisor);

  useEffect(() => {
    loadChats();
    getCurrentChatId();
  }, []);

  useEffect(() => {
    if (chatId) {
      loadMessages(chatId);
    }
  }, [chatId]);

  useEffect(() => {
    if (chatId) {
      for (let c of chats) {
        if (c.id === chatId) {
          loadProperty(c.propertyId);
          setChat(c);
          break;
        }
      }
    }
  }, [chats, chatId]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  function scrollToBottom() {
    if (messagesEndScrollRef.current) {
      messagesEndScrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }

  async function loadProperty(propertyId) {
    if (propertyId) {
      setProperty(await PropertiesController.Get(propertyId));
    }
  }

  function getCurrentChatId() {
    const params = new URLSearchParams(props.location.search);
    const chatId = params.get("chatId");
    if (chatId) {
      setChatId(decodeURIComponent(chatId));
    }
  }

  function loadChats() {
    if (chatsUnsubscribe) {
      chatsUnsubscribe();
    }
    chatsUnsubscribe = ChatController.GetChats((onChats) => {
      let chats = onChats.chats;
      setChats(chats);
    });
  }

  function loadMessages(chatId) {
    if (messagesUnsubscribe) {
      messagesUnsubscribe();
    }
    messagesUnsubscribe = ChatController.GetMessages(chatId, (onMessages) => {
      let messages = onMessages.messages;
      if (Array.isArray(messages)) {
        messages = messages.sort((a, b) => {
          if (a.createdDate instanceof Timestamp) {
            return a.createdDate.toMillis() - b.createdDate.toMillis();
          } else {
            return a.createdDate - b.createdDate;
          }
        });
      }
      setMessages(messages);
    });
  }

  function handleFileChange(event) {
    setMedia([...event.target.files]);
    console.log("SelectedFiles: ", event.target.files);
  }

  function handleFormSubmit(event) {
    event.preventDefault();
    sendMessage();
  }

  async function sendMessage() {
    if (!userData || (message.trim() === "" && media.length === 0)) {
      return;
    }

    try {
      setIsSubmitting(true);
      let fileResults = [];
      if (media.length !== 0) {
        let taskResults = await Promise.all(
          media.map((item) =>
            FileController.UploadFile(
              `chats/${chatId}/${StringHelper.GenerateUniqueId()}`,
              item
            )
          )
        );

        fileResults = taskResults.filter((r) => r);
        if (fileResults.length === 0) {
          return;
        }
      }

      await ChatController.SendMessage({
        chatId: chatId,
        text: message.trim(),
        sender: {
          id: userData.uid,
          name: userData.name,
          picture: userData.picture,
        },
        media:
          fileResults.length !== 0
            ? fileResults.map((metadata) => ({
                url: metadata.url,
                name: metadata.name,
                contentType: metadata.contentType,
                fullPath: metadata.fullPath,
                bucket: metadata.bucket,
              }))
            : null,
      });

      // Clear values
      removeMedia();
      setMessage("");
    } finally {
      setIsSubmitting(false);
    }
  }

  function openChat(id) {
    if (chatId === id) {
      return;
    }

    window.location = `#/chat?chatId=${id}`;

    // Clear values
    removeMedia();
    setMessages([]);
    setMessage("");
    setChatId(id);
  }

  function removeMedia() {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    setMedia([]);
  }

  return (
    <div className={isMobile === true ? "" : "row"}>
      {chat && isMobile === true ? (
        ""
      ) : (
        <div className="col-md-4">
          {chats &&
            chats.map((chatItem) => (
              <a
                key={chatItem.id}
                className="d-block mb-2 p-2 text-decoration-none bg-white border rounded shadow-sm"
                href={`javascript:void(0)`}
                onClick={() => openChat(chatItem.id)}
              >
                <div className="d-flex align-items-center">
                  <img
                    className="img-avatar img-avatar48 img-avatar-thumb img-avatar-rounded border border-secondary"
                    src={
                      chatItem.peer?.picture ||
                      `${process.env.PUBLIC_URL}/assets/media/avatars/avatar0.jpg`
                    }
                    alt=""
                  />
                  <div className="ml-3">
                    <span className="font-weight-bold text-dark">
                      {chatItem.peer?.name}
                    </span>
                    <br />
                    <small className="text-muted">
                      {chatItem.lastMessage?.sender.name}:
                    </small>
                    <br />
                    <small className="text-muted">
                      {chatItem.lastMessage?.text ? (
                        StringHelper.TruncateText(
                          chatItem.lastMessage.text,
                          128
                        )
                      ) : (
                        <i className="fa fa-camera" aria-hidden="true"></i>
                      )}
                    </small>
                  </div>
                </div>
                <div
                  className={`text-right text-white font-size-sm ${
                    chatItem.seen === false ? "bg-warning" : "bg-gray"
                  } p-1 rounded mt-2`}
                >
                  {IsTodayDate(chatItem.updatedDate)
                    ? DateToPast(chatItem.updatedDate)
                    : DateTranslate(chatItem.updatedDate)}
                </div>
              </a>
            ))}
        </div>
      )}
      {(chat && isMobile === true) || isMobile === false ? (
        <div
          className={`${
            isMobile === true
              ? "bg-body-light"
              : "ml-0 pl-0 col-md-8 bg-body-light"
          }`}
          style={{ marginTop: isMobile === true ? -20 : 0 }}
        >
          <div className="bg-white border border-bottom">
            <div>
              <div className="block-header">
                <div className="block-title d-flex">
                  <img
                    className="img-avatar img-avatar48 img-avatar-thumb img-avatar-rounded border border-secondary"
                    src={
                      chat.peer?.picture ||
                      `${process.env.PUBLIC_URL}/assets/media/avatars/avatar0.jpg`
                    }
                    alt=""
                  />
                  <div className="ml-3">
                    <span className="font-weight-bold text-dark">
                      {chat.peer?.name || "-"}
                    </span>
                    <br />
                    {property && property.id && (
                      <a
                        className="cursor"
                        target="_blank" rel="noreferrer"
                        href={
                          property && `${GetBaseUrl()}#/imovel/${property.id}`
                        }
                      >
                        {property && property.title}{" "}
                        <i className="si si-share-alt ml-2" />
                      </a>
                    )}
                  </div>
                </div>
                <div className="block-tools">
                  <small className="text-muted">
                    {"Última mensagem: "}
                    {chat?.updatedDate &&
                      (IsTodayDate(chat.updatedDate)
                        ? GetHours(chat.updatedDate)
                        : DateTranslate(chat.updatedDate))}
                  </small>
                </div>
              </div>
            </div>
          </div>
          {/* TODO: Fix div height height / divisor */}
          <div
            style={{ height: height / divisor, overflowY: "scroll" }}
            className="block-content mb-1 pb-1"
          >
            {property && property.id ? <RealtyInLine data={property} /> : ""}
            {messages && messages.length === 0 ? (
              <div className="text-center py-5">
                Não existem mensagens disponíveis
              </div>
            ) : (
              ""
            )}
            {messages &&
              messages.map((msg) => (
                <div
                  key={msg.id}
                  className={`d-flex mb-3 ${
                    msg.sender.id === userData.uid ? "justify-content-end" : ""
                  }`}
                >
                  <div className="w-75">
                    <div className="media p-2 border rounded shadow-sm bg-light">
                      <img
                        className="img-avatar img-avatar32 img-avatar-thumb mr-2"
                        src={
                          msg.sender?.picture ||
                          `${process.env.PUBLIC_URL}/assets/media/avatars/avatar0.jpg`
                        }
                        alt=""
                      />
                      <div className="media-body">
                        {msg.media?.[0] && (
                          <img
                            height={512}
                            width={512}
                            src={msg.media[0].url}
                            alt=""
                            onLoad={() => scrollToBottom()}
                            className="img-fluid rounded mb-2"
                          />
                        )}
                        <p
                          className="mb-1 text-dark"
                          style={{
                            overflowWrap: "break-word",
                            wordBreak: "break-word",
                          }}
                        >
                          <strong>{msg.sender?.name}</strong>
                          <br />
                          {msg.text}
                        </p>
                        <small className="text-muted">
                          {IsTodayDate(msg.createdDate)
                            ? GetHours(msg.createdDate)
                            : DateTranslate(msg.createdDate)}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            <div style={{ height: 80 }} />
            <div ref={messagesEndScrollRef} />
          </div>
        </div>
      ) : (
        ""
      )}
      {(chat && isMobile === true) || isMobile === false ? (
        <div
          className="col-md-8 ml-0 mr-0 mt-0 bg-white"
          style={
            isMobile === false
              ? { position: "fixed", bottom: 0, right: 0 }
              : { position: "fixed", right: 0 }
          }
        >
          <div
            className={`border-top pt-3 mt-3 mb-3 ${
              isMobile
                ? "position-fixed bottom-0 left-0 right-0 bg-light p-3"
                : ""
            }`}
          >
            <form
              onSubmit={handleFormSubmit}
              className="d-flex align-items-center"
            >
              <img
                className="img-avatar img-avatar32 img-avatar-thumb mr-2"
                src={
                  userData?.picture ||
                  `${process.env.PUBLIC_URL}/assets/media/avatars/avatar0.jpg`
                }
                alt=""
              />
              <input
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Escreva uma mensagem..."
                type="text"
                className="form-control border-0 p-2"
                disabled={isSubmitting}
                style={{
                  resize: "none",
                  overflowWrap: "break-word",
                  wordBreak: "break-word",
                  maxHeight: "50px",
                }}
              />
              <input
                type="file"
                multiple={false}
                ref={fileInputRef}
                accept="image/gif, image/jpeg, image/png"
                onChange={handleFileChange}
                disabled={isSubmitting}
                style={{ display: "none" }}
              />
              <button
                onClick={() => fileInputRef.current.click()}
                className="btn btn-sm btn-dual mx-2"
                disabled={isSubmitting}
                type="button"
              >
                <i className="fa fa-paperclip" aria-hidden="true"></i>
              </button>
              <button
                onClick={sendMessage}
                className="btn btn-sm btn-dual"
                disabled={isSubmitting}
                type="button"
              >
                <i className="fa fa-paper-plane" aria-hidden="true"></i>
              </button>
            </form>

            {/* Selected files */}
            <div style={{ marginLeft: 50 }}>
              {media.length !== 0 ? `${media.length} arquivo selecionado ` : ""}
              {media.length !== 0 && (
                <button
                  onClick={(event) => removeMedia()}
                  className="btn btn-rounded btn-sm p-1"
                  type="button"
                >
                  <i className="si si-close" aria-hidden="true"></i>
                </button>
              )}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
