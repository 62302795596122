import { toast } from "react-toastify";

export const UserHelper = {
  UserLevel: {
    User: 0,
    Realtor: 1,
    RealEstate: 2,
    Administrator: 10,
  },

  UserLevelList: [
    { name: "Usuário", value: 0 },
    { name: "Corretor", value: 1 },
    { name: "Corretor de uma imobiliária", value: 2 },
    { name: "Administrador", value: 10 },
  ],
  Roles: {
    User: 0,
    Administrator: 1,
  },

  RoleList: [
    { name: "Usuário", value: 0 },
    { name: "Administrador", value: 1 },
  ],
  RoleTranslator: function (value) {
    const result = this.RoleList.filter((x) => x.value === value);
    if (result.length > 0) {
      return result[0].name;
    } else {
      return "S.I.";
    }
  },

  LevelTranslator: function (value) {
    const result = this.UserLevelList.filter((x) => x.value === value);
    if (result.length > 0) {
      return result[0].name;
    } else {
      return "S.I.";
    }
  },

  ValidaCNPJ: function (cnpj) {
    var b = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
    var c = String(cnpj).replace(/[^\d]/g, "");

    if (c.length !== 14 || /0{14}/.test(c)) return false;

    // Validate first check digit
    var n = 0;
    for (var i = 0; i < 12; i++) {
      n += c[i] * b[i + 1];
    }
    var checkDigit1 = n % 11 < 2 ? 0 : 11 - (n % 11);
    if (c[12] !== checkDigit1) return false;

    // Validate second check digit
    n = 0;
    for (var i = 0; i <= 12; i++) {
      n += c[i] * b[i];
    }
    var checkDigit2 = n % 11 < 2 ? 0 : 11 - (n % 11);
    if (c[13] !== checkDigit2) return false;

    return true;
  },

  CPFValidator: function (strCPF) {
    var sum = 0;
    var remainder;

    if (strCPF === "00000000000") return false;

    for (let i = 1; i <= 9; i++) {
      sum += parseInt(strCPF.charAt(i - 1)) * (11 - i);
    }
    remainder = (sum * 10) % 11;
    if (remainder === 10 || remainder === 11) remainder = 0;
    if (remainder !== parseInt(strCPF.charAt(9))) return false;

    sum = 0;
    for (let i = 1; i <= 10; i++) {
      sum += parseInt(strCPF.charAt(i - 1)) * (12 - i);
    }
    remainder = (sum * 10) % 11;
    if (remainder === 10 || remainder === 11) remainder = 0;
    if (remainder !== parseInt(strCPF.charAt(10))) return false;

    return true;
  },

  PasswordIsStrong: function (password) {
    let senha = password;
    let forca = 0;
    if (senha.length >= 4 && senha.length <= 7) {
      forca += 10;
    } else if (senha.length > 7) {
      forca += 25;
    }
    if (senha.match(/[a-z]+/)) {
      forca += 10;
    }
    if (senha.match(/[A-Z]+/)) {
      forca += 20;
    }
    if (senha.match(/d+/)) {
      forca += 20;
    }
    if (senha.match(/W+/)) {
      forca += 25;
    }

    if (forca < 30 || forca === 35) {
      return false;
    } else if (forca > 35 && forca < 60) {
      return true;
    } else if (forca >= 60 && forca < 85) {
      return true;
    } else {
      return true;
    }
  },
};
