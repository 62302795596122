import Modal from "@/app/core/layouts/main/components/Modal";
import React, { useEffect, useState } from "react";
import Field from "@/app/core/components/input/Field";
import { FieldTypeObj } from "@/app/core/util/helpers/FieldTypeHelper";
import { toast } from "react-toastify";
import { Dropdown, Tab, Tabs } from "react-bootstrap";
import { OnDataChange } from "@/app/core/util/helpers/DataHelper";
import { Message } from "@/app/core/util/messages/GeralMessages";
import { PlansController } from "@/app/core/base/controllers/PlanController";
import SignIn from "@/app/modules/auth/sign-in";
import Register from "@/app/modules/register/main";

export default function AccountModal({ show, onHide }) {
    const date = new Date();
    const [url, setUrl] = useState("");
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const [change, setChange] = useState(date.getTime());

    useEffect(() => {
    }, [show]);

    function notifyChange() {
        setChange(date.getTime());
    }

    async function save() {}

    return (
        <Modal title="Acessar a conta" size="md" hideAllButtons={true} show={show} onHide={onHide} isLoading={loading} onSubmit={() => save()}>

            <Tabs
                defaultActiveKey="login" id="uncontrolled-tab-example" className="mb-3">
                <Tab eventKey="login" title="Login" className="">
                    <SignIn intern={true} />
                </Tab>
                <Tab eventKey="cadastro" title="Cadastro" className="">
                    <Register intern={true} />
                </Tab>
            </Tabs>
        </Modal>
    );
}
