import { PropertiesController } from "@/app/core/base/controllers/PropertiesController";
import { CurrencyHelper } from "@/app/core/util/helpers/CurrencyHelper";
import { RealtyHelper } from "@/app/core/util/helpers/RealtyHelper";
import { Message } from "@/app/core/util/messages/GeralMessages";
import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
var Carousel = require('react-responsive-carousel').Carousel;

export default function OwnerRealtyCard({ data, reload }) {
    const [realty, setRealty] = useState({});

    useEffect(() => {
        setRealty(data);
        console.log("Oaljdnsajkld", data)
    }, [data]);

    function onChange() { }

    function onClickThumb() { }

    function editAd() {
        window.location = `#/anunciar-imovel?propertyId=${realty.id}&imovel=${realty.title}`;
    }

    async function removeAdd() {
        if (window.confirm(Message.DeleteConfirm)) {
            let ad = realty;
            ad.status = RealtyHelper.Status.Canceled;
            await PropertiesController.Update(ad);
            reload()
        }
    }

    return (
      <div className="block block-rounded block-fx-pop">
        <div className="block-content p-0 overflow-hidden">
          <Carousel
            showArrows={true}
            onChange={onChange}
            showThumbs={false}
            onClickThumb={onClickThumb}
          >
            {realty &&
              realty.images &&
              realty.images.map((item) => (
                <div key={item.url} className="cursor">
                  <img
                    src={item.url}
                    style={{ objectFit: "cover", height: 346, width: "100%" }}
                  />
                </div>
              ))}
          </Carousel>
          <button
            className="btn btn-white"
            style={{ position: "absolute", top: 0, margin: 22, right: 0 }}
          >
            {RealtyHelper.TranslateAdType(realty.type)}
          </button>
        </div>
        <div className="block-content">
          <h4 className="h6 mb-2 mb-0">{realty && realty.title}</h4>
          <h5 className="h2 font-w300 push mb-0">
            <span className="font-size-h3 text-muted mb-0">
              {realty && realty.neighborhood} - {realty && realty.city}
            </span>
          </h5>
        </div>
        <div className="block-content m-0 p-0">
          <div className="row p-0 m-0">
            {/* {realty && realty.amenities && realty.amenities.map(item => {

                            for (let s in item.optionals) {
                                if (item.optionals[s].active) {
                                    return <div className="col-6">
                                        <p className="py-3 mb-0">
                                            {item && item.optionals[s].icon && <img src={item.optionals[s].icon} style={{ height: 20 }} />}
                                        </p>
                                    </div>
                                }
                            }
                        })} */}
            <div className="col-12">
              <h3 className="text-primary ml-2">
                {realty && realty.value && CurrencyHelper.Format(realty.value)}
              </h3>
            </div>
            <div className="col-6">
              <button
                className="btn btn-sm btn-hero btn-noborder btn-secondary btn-block mb-3"
                onClick={() => editAd()}
              >
                <i className="far fa-edit mr-2" /> Editar
              </button>
            </div>
            <div className="col-6">
              <button
                className="btn btn-sm btn-hero btn-noborder btn-primary btn-block mb-3"
                onClick={() => removeAdd()}
              >
                <i className="si si-close text-danger mr-2" /> Remover
              </button>
            </div>
          </div>
        </div>
      </div>
    );
}